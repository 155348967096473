var enabled = localStorage.getItem('enabled');
const french = $('.fr')
const english = $('.en')
const brandBlue = "#00516d"

  if(enabled === 'enabled'){
  french.css({
      "background-color":brandBlue,
      "color":"white"
    })

    english.css({
        "background-color":"white",
        color:brandBlue
    })
  }else{
    
    english.css({
      "background-color":brandBlue,
      "color":"white"
    })

    french.css({
        "background-color":"white",
        color:brandBlue
    })

  }



french.click(function(){
  //$('.goog-te-combo').val("fr");
  function initializeTranslation() {
    const fr_trig = document.querySelector('.goog-te-combo');
    if (fr_trig) { // Check if the element exists
        fr_trig.value = 'fr';
        triggerEvent(fr_trig, 'change');
        // ... rest of your code
    } else {
        console.error("Element with class 'goog-te-combo' not found.");
    }
}

// Call the function after a delay or when Google Translate is ready
setTimeout(initializeTranslation, 1); // Adjust the delay as needed
   
//triggerEvent(fr_trig, 'change');
    localStorage.setItem('enabled','enabled');

   
        $(this).css({
          "background-color":brandBlue,
          "color":"white"
        })

        english.css({
            "background-color":"white",
            color:brandBlue
        })
   
        
  
})

english.click(function(){
 //$('.goog-te-combo').val("en");
 function initializeTranslation() {
    const trig = document.querySelector('.goog-te-combo');
    if (trig) { // Check if the element exists
        trig.value = 'en';
        triggerEvent(trig, 'change');
        // ... rest of your code
    } else {
        console.error("Element with class 'goog-te-combo' not found.");
    }
}

// Call the function after a delay or when Google Translate is ready
setTimeout(initializeTranslation, 1); // Adjust the delay as needed

  localStorage.setItem('enabled',null);
    $(this).css({
        "background-color":brandBlue,
        "color":"white"
    })

    french.css({
        "background-color":"white",
        color:brandBlue
    })
})

setTimeout(()=>{
    function googleTranslateElementInit() {
        new google.translate.TranslateElement({
            pageLanguage: 'de'
          },
          'google_translate_element'
        );
    }

    googleTranslateElementInit()
},500)


  

  const triggerEvent = (element, eventName) => {
    const event = new Event(eventName);
    element.dispatchEvent(event);
  };

//   function changeLanguage() {
//     $('.goog-te-combo').val("fr");
//     triggerEvent(document.querySelector('.goog-te-combo'), 'change');


  
//   }

//   function changeLanguage_en() {
//     $('.goog-te-combo').val("en");
//     triggerEvent(document.querySelector('.goog-te-combo'), 'change');

    
//   }

//   document.addEventListener('DOMContentLoaded', function() {
//     // Now call changeLanguage or changeLanguage_en
//     changeLanguage(); 
//     // or 
//     changeLanguage_en();
// });

